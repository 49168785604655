<template lang="pug">
  #login
    b-container
      b-row(align-v="center").justify-content-center
        b-col(md="5").my-5
          b-card(header="Iniciar Sesión")
            b-card-text
              b-form(@submit.prevent="onSubmit")
                b-form-group(label="Dirección de email:")
                  b-form-input(
                    v-model="loginObj.email"
                    type="email"
                    required
                    placeholder="email@dominio.com"
                    autocomplete="email"
                    autofocus
                  )
                b-form-group(label="Contraseña:")
                  b-input#text-password(
                    v-model="loginObj.password"
                    type="password"
                    aria-describedby="password-help-block"
                  )
                b-row
                  b-col(md="12").mb-sm-2
                    b-button(
                      type="submit"
                      variant="outline-mhmotors"
                      block=""
                      :disabled="butonDisabledLogin"
                    )
                      font-awesome-icon(icon="sign-in-alt" v-if="buttonTextLogin == 'Iniciar sesión'")
                      |  {{ buttonTextLogin }}
                  //b-col(md="12")
                    b-button(href="/register", variant="outline-mhmotors", block="") Regístrate
                  b-col(md="12")
                    b-button.my-2(variant="link", data-toggle="modal", block="", @click="ShowRecoveryPasswordModal") ¿Olvidaste tu contraseña?
    b-modal(v-model="modelRecoveryPasswordModal", hide-footer="", title="Recuperar contraseña")
      b-form(@submit.prevent="recoverPasswordSubmit")
        .modal-body
          .form-group.row
            .col-md-12
              label.col-form-label.text-md-right(for="email") Dirección de email
              input.form-control(v-model="emailRecover", type="email", value="", placeholder="email@dominio.com", required="", autocomplete="email", autofocus="")
        .modal-footer
          b-button(type="submit", variant="mhmotors", block="", :disabled="butonDisabledRecovery") {{ buttonTextRecovery }}
</template>
<script>
import { mapActions, mapState } from "vuex"
export default {
  name: "Login",
  data() {
    return {
      modelRecoveryPasswordModal: false,
      emailRecover: "",
      butonDisabledLogin: false,
      buttonTextLogin: "Iniciar sesión",
      butonDisabledRecovery: false,
      buttonTextRecovery: "Recuperar contraseña",
      loginObj: {
        email: "",
        password: ""
      }
    };
  },
  created() {
    if(this.credentials.id) this.$router.push("dashboard")
  },
  computed: {
    ...mapState("auth",["credentials"]),
  },
  methods: {
    ...mapActions("auth",["login","checkIsRole"]),
    ...mapActions("image",["getMaxImages"]),
    ShowRecoveryPasswordModal() {
      this.modelRecoveryPasswordModal = true;
    },
    async onSubmit() {
      try {
        this.butonDisabledLogin = true
        this.buttonTextLogin = "Cargando..."
        const credentials = {
          email: this.loginObj.email,
          password: this.loginObj.password
        };
        const { success } = await this.login(credentials)
        if(typeof success !== 'undefined' && success.hasOwnProperty('token')){
          this.butonDisabledLogin = false;
          this.buttonTextLogin = "Iniciar sesión";
          this.$bvToast.toast("Inicio de sesión", {
            title: "Inicio de sesión con exito",
            variant: "success",
            solid: true
          });
          await this.checkIsRole()
          await this.getMaxImages()
          this.$router.push("dashboard");
        }else {
        }
      } catch (error) {
        this.butonDisabledLogin = false;
        this.buttonTextLogin = "Iniciar sesión";
      }
    },
    recoverPasswordSubmit() {
      this.butonDisabledRecovery = true;
      this.buttonTextRecovery = "Cargando ...";
      const formData = {
        email: this.emailRecover
      };
      this.$axios
        .post("api/v1/create", formData)
        .then(res => {
          this.modelRecoveryPasswordModal = false;
          this.emailRecover = "";
          this.butonDisabledRecovery = false;
          (this.buttonTextRecovery = "Enviar"),
            this.$swal.fire(
              "Operacion realizada con exito",
              res.data.message,
              "success"
            );
        })
        .catch(function(error) {
          this.butonDisabledRecovery = false;
          (this.buttonTextRecovery = "Enviar"),
            this.$bvToast.toast("Recuperación de contraseña", {
              title: error.response.data.message,
              variant: "info",
              solid: true
            });
        });
    }
  },
};
</script>
